<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <div>
      <el-form :inline="true">
        <el-form-item label="状态">
          <el-select v-model="status" placeholder="请选择" clearable>
            <el-option
              v-for="item in StatusList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <auto-table :DataList="list" :option="option" @changePage="changePage">
      <el-table-column label="状态">
        <template slot-scope="{ row }">
          <p v-if="row.status == 2" style="color: #999">待审核</p>
          <p v-if="row.status == 3" style="color: green">已同意</p>
          <p v-if="row.status == 4" style="color: red">已拒绝</p>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-button type="primary" size="small" @click="agree(row)"
            >同意</el-button
          >
          <el-button type="danger" size="small" @click="refuse(row)"
            >拒绝</el-button
          >
        </template>
      </el-table-column>
    </auto-table>
  </div>
</template>
    
    <script>
import axios from "axios";
export default {
  data() {
    return {
      list: [],
      currentPage: 1,
      total: 0,
      formInline: {},
      status: "2",
      type: "",
      nav: { firstNav: "课程管理中心", secondNav: "课程补签管理" },
      StatusList: [
        {
          value: "2",
          name: "待处理",
        },
        {
          value: "3",
          name: "已同意",
        },
        {
          value: "4",
          name: "已拒绝",
        },
      ],
      option: [
        {
          name: "id",
          value: "id",
        },
        {
          name: "上课时间",
          value: "start_time",
        },
        {
          name: "下课时间",
          value: "end_time",
        },
        {
          name: "课程名称",
          value: "title",
        },
        {
          name: "机构名称",
          value: "mechanism_name",
        },
      ],
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    agree(row) {
      this.$confirm("是否同意课程补签").then(() => {});
    },
    refuse(row) {
      this.$confirm("请确认是否拒绝课程补签").then(() => {});
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    changePage(v) {
      this.currentPage = v;
      this.getList();
    },
    getList() {
      let params = {
        currentPage: this.currentPage,
        status: this.status,
        type: this.type || null,
        pageSize: 10,
      };
      axios({
        url: "http://localhost:8080/user/repair",
        params,
      }).then((res) => {
        console.log(res);
        this.list = res.data.data;
        this.total = res.data.data.total;
      });
    },
    currentPageChange(v) {
      this.currentPage = v;
      this.getList();
    },
  },
};
</script>
    
    <style lang="scss" scoped>
</style>